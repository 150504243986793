.App {
  text-align: center;
  background-color: #0f0f15;
}

.container {
  max-width: 1650px;
  margin: 0 auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.header-content {
  width: 88%;
}

.form-label {
  display: block;
  margin-bottom: 8px;
  color:#929292;
}
// shadow appearance-none border rounded-md border-slate-800 w-full py-2 px-3 text-gray-700 leading-tight bg-black focus:outline-none focus:shadow-outline
.form-input {
  width: 100%;
  margin-bottom: 6px;
  padding: 0.43rem 1rem;
  background-color: #101016 !important;
  border: 1px #3b3b3b solid;
  border-radius: 10px;
  &:focus-visible {
    border: 1px rgb(88, 88, 88) solid !important;
  }
}


.validation-msg {
  max-width: 280px;
  padding-left: 8px;
  color: rgb(218, 97, 97);
}

$main-color: #1848a9;

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

iframe {
  width: 100%;
  overflow: hidden;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn {
  background-color: rgba(59, 130, 246, var(--tw-text-opacity));
  padding: 0.5rem 1.2rem;
  border-radius: 30px;
  z-index: 200;
}

.btn-mint {
  border: $main-color solid 2px;
  padding: 0.5rem 2rem;
  border-radius: 30px;
  z-index: 200;
  cursor: pointer;
}

.btn-mint:hover {
  background-color: $main-color;
}

.btn:hover {
  opacity: 0.7;
}

.about-link:hover {
  opacity: 70%;
}

.coming-soon {
  background: rgba(14, 14, 14, 0.61);
}

.hero-fade {
  background: linear-gradient(159deg, rgba(0,0,0,0.935749299719888) 42%, rgba(0,0,0,0.3925945378151261) 72%, rgba(0,0,0,0.14049369747899154) 99%);
}

.hero-container {  
  background-image: url('../src/assets/bg-marble-wide.png'); 
  background-size: cover;
}

.validation-msg {
  max-width: 200px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #e2e1e1;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
    caret-color: #e2e1e1;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: $main-color;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.hero-content {
  max-width: 1400px;
  margin: 0 auto;
}

@media (max-width: 600px) {
  .claimForm {
    overflow-y: scroll;
    max-height: 80vh;
    margin-bottom: 20px;
    padding: 0px 10px;
    padding-top: 28px;
  }

  .claim-btns {
    
  }
}


@media (min-width: 600px) {
  .hero-content {
    width: 93%;
  }
}

@media (min-width: 1100px) {
  .hero-content {
    width: 83%;
  }
}


.story-body {
  max-width: 950px;
  margin: 0 auto;
  p {
    margin-bottom: 12px;
  }
}

.story-body-interactive {
  max-width: 1200px;
  margin: 0 auto;
  p {
    margin-bottom: 12px;
  }
}

.story-grid {
  display: grid;
}

.story-btn {
}

.story-fade {
  left: 0;
  top: 0;
  background: linear-gradient(180deg, rgba(0,0,0,0.2150035014005602) 32%, rgba(0,0,0,0.8) 74%, rgba(0,0,0,0.96) 89%);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.767);
}


@media (min-width: 400px) {
  .story-grid { grid-template-columns: repeat(1, 1fr); }
}

@media (min-width: 600px) {
  .story-grid { grid-template-columns: repeat(2, 1fr); }
}

@media (min-width: 1000px) {
  .story-grid { grid-template-columns: repeat(3, 1fr); }
}
@media (min-width: 1400px) {
  .story-grid { grid-template-columns: repeat(4, 1fr); }
}


.story-card {
  min-height: 250px;
}

@media (prefers-reduced-motion: no-preference) {
  html {
    scroll-behavior: smooth;
  }
}

.text-highlight {
  color: #2d69e2;
}

.btn {
  background-color: $main-color;
  color: rgb(255, 255, 255);
}

// link appearing
$scrollLength: 160vh;

.back-to-top-wrapper {
  // uncomment to visualize "track"
  // outline: 1px solid red;
  position: absolute;
  top: $scrollLength;
  right: 0.25rem;
  bottom: 6em;
  padding-top: 1rem;
  padding-right: 1rem;
  pointer-events: none;
}

.back-to-top-link {
  // `fixed` is fallback
  position: fixed;
  // preferred positioning, requires prefixing for most support, and not supported on Safari
  // @link https://caniuse.com/#search=position%3A%20sticky
  position: sticky;
  // reinstate clicks
  pointer-events: all;
  // achieves desired positioning within the viewport
  // relative to the top of the viewport once `sticky` takes over, or always if `fixed` fallback is used
  top: calc(100vh - 5rem);

  transition: transform 80ms ease-in;

}

.consent-box {
  max-width: 800px;
  background: radial-gradient( circle farthest-corner at 83.7% 4.3%,  rgb(47, 47, 58) 0%, rgba(24,24,37,1) 90% );
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsla(201, 20%, 80%, 0.425);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.sign-out-link:hover {
    border-bottom: 2px solid;
    border-bottom-color: #61dafb;
}

.consent-box-wrapper {
}

.story-card {
  background-size: cover;
}

.story-card-title {
  border-color: #d39647;
}

/* Colorize-zoom Container */
.grayscale {
  filter: grayscale(100%) !important;
}

.img-hover-zoom {
  transition: transform 1s, filter 1.5s ease-in-out;
  transform-origin: center center;
  filter: brightness(80%);
}

/* The Transformation */
.img-hover-zoom:hover {
  filter: brightness(100%);
  transform: scale(1.01);
}

.loader {
  color: #d97706;
  font-size: 12px;
  margin: 30px auto;
  margin-bottom: 45px;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}
@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

.spinner-container {
  top: 45vh;
  width: 100%;
  position: absolute;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 75px;
  height: 75px;
  color:  $main-color;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid $main-color;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color:  $main-color transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner-overlay {
  position: fixed;
  top: 50vh;
  left: 40%;
  z-index: 9000;
}

.interactive-title {
  margin-top: 5rem !important;
}







